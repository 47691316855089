// Product Charge card
.card-charge {
  width: 100%;
  max-height: 80px;
  border-radius: 8px;
  background: #fff;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .block-charge {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px;

    span {
      &:first-child {
        font-weight: 500;
        font-size: 14px;
      }
      &:last-child {
        font-size: 14px;
        color: #828282;
      }
    }
  }

  .icon-charge {
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 40px;
    height: 80px;
    border-radius: 0px 8px 8px 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  .icon-charge-success {
    background: #00c853;
  }
  .icon-charge-warning {
    background: #e07b07;
  }
  .icon-charge-danger {
    background: #f1003b;
  }

  & + .card-charge {
    margin-bottom: 8px;
    margin-top: 8px;
  }
}

// Product Item card
.not-found__product-items {
  background: #eee;
  width: 100%;
  height: 250px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.list-product-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.list-product-items {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 8px;
}

.card-product {
  background: #fff;
  padding: 8px;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .card-content {
    max-width: 250px;

    h4 {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .card-value {
    font-size: 14px;
    font-weight: 500;
  }

  & + .card-product {
    margin-top: 8px;
  }
}
