.color-picker {
  .color-picker__color {
    @include directify($directions) {
      #{directed('margin-right')}: auto;
      #{directed('margin-left')}: 0px;
    }
    margin-top: auto;
    margin-bottom: auto;
    direction: ltr;
    @include directify($directions) {
      #{directed('padding-left')}: 10px;
    }
  }
}

.color__status__active {
  @include themify($themes) {
    border: 2px solid themed("colorActive");
  }
}

.color__status__desactive {
  @include themify($themes) {
    border: 2px solid themed("colorDesactive");
  }
}

.color-picker__button {
  height: 32px;
  width: 160px;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  background: transparent;
  padding: 0;

  @include themify($themes) {
    border: 1px solid themed("colorFieldsBorder");
  }

  &.active {
    border-color: $color-accent;
  }
}

.color-picker__color-view {
  height: 30px;
  width: 50px;
}

.color-picker__popover.popover {
  width: auto;

  .block-picker {
    & > div:first-child {
      display: none;
    }
  }

  .block-picker,
  .chrome-picker,
  .sketch-picker {
    box-shadow: none !important;
  }
}
